import React from "react";
import loadable from "@loadable/component";

const PageContainer = loadable(() => import("@layout/DefaultPageContainer"));

const PageBuilder = loadable(() =>
  import("../components/templates/PageBuilder")
);
const PageHero = loadable(() => import("@organisms/PageHero"));

const HomePage = ({ data, ...rest }) => {
  const { hero, blocks } = data;
  return (
    <PageContainer {...rest} flex={false}>
      {hero.heading && <PageHero {...hero} />}
      <PageBuilder blocks={blocks} hasHero={hero.heading} />
    </PageContainer>
  );
};

HomePage.defaultProps = {};

export default HomePage;
